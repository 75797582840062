import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useMemo } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { BulkUnlockable, Unlockable } from "./types";
import { useTranslation } from "react-i18next";
import { ScheduleCategory } from "./ScheduleCategory/ScheduleCategory";
import { unlockableArrayToMap } from "./functions/unlockableArrayToMap";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";

interface Props {
  open: boolean;
  onClose: () => void;
  bookingId?: number;
  bookingType: string;
  unlockableData: Unlockable[];
  bulkUnlockData?: BulkUnlockable[];
  title: "target" | "bulk" | string; // to distinct the use of modal bulk or target unlockable schedule
  checkedIn: boolean;
  unCheckedinSchedules: BookingScheduleInterface | undefined;
  refetchAllSchedule: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ScheduleUnlockDialog({
  open,
  onClose,
  bookingId,
  bookingType,
  unlockableData,
  bulkUnlockData,
  title,
  checkedIn,
  unCheckedinSchedules,
  refetchAllSchedule
}: Props) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600upx)");

  const sortedEntries = useMemo(() => {
    if (bulkUnlockData) return unlockableArrayToMap(bulkUnlockData);
    return unlockableArrayToMap(unlockableData);
  }, [unlockableData, bulkUnlockData]);

  // To add new categories, change this array
  const keys = ["location", "zone", "floor"];

  return (
    <Dialog
      PaperProps={{ sx: { backgroundImage: "none" } }}
      onClose={onClose}
      open={open}
      fullScreen={matches}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex" }}>
          <Typography>{t("UnlockTitle")}</Typography>
          <Box flex={1} />
          <IconButton onClick={onClose} size="large">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ display: "flex", justifyContent: "center" }}
        data-testid="schedule-unlock-dialog-items-parent"
      >
        <Stack sx={{ m: 2, width: { sm: 480, xs: "100%" } }} gap={4}>
          {keys.map(unlockableCategory => (
            <ScheduleCategory
              key={uuidv4()}
              bookingId={bookingId}
              unlockableCategory={unlockableCategory}
              sortedEntries={sortedEntries}
              bookingType={bookingType}
              title={title}
              checkedIn={checkedIn}
              unCheckedinSchedules={unCheckedinSchedules}
              refetchAllSchedule={refetchAllSchedule}
            />
          ))}
        </Stack>
      </Box>
    </Dialog>
  );
}
