import React, { FunctionComponent } from "react";
import { Add, Done, FilterList } from "@mui/icons-material";
import { PricingModel } from "../../features/Login/typings/login.types";
import { NotSerializedBookingInputs } from "../../features/Booking-Form/typings/booking-inputs";
import { useSelector } from "../../app/helpers";
import { useTranslation } from "react-i18next";
import { RootState } from "../../app/rootReducer";
import { Button, Chip, Grid } from "@mui/material";

interface OwnProps {
  inputs: NotSerializedBookingInputs;
  currentUser: number;
  setCurrentUser: React.Dispatch<React.SetStateAction<number>>;
  setAnchorElDevice: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setAnchorElDeviceCategories: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

type Props = OwnProps;

const FormWorkplaceTeamBookingChips: FunctionComponent<Props> = ({
  inputs,
  currentUser,
  setCurrentUser,
  setAnchorElDevice,
  setAnchorElDeviceCategories
}) => {
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { t } = useTranslation();

  return (
    <>
      <Grid data-testid="team-member-chips-container" container>
        {inputs.mode === "team" &&
          inputs.usersBookedFor?.map((user, index) => (
            <Chip
              data-testid="team-member"
              label={user.firstName + " " + user.surname}
              style={{ margin: "0 10px 10px 0" }}
              clickable
              key={user.userId}
              color={currentUser === index ? "primary" : "default"}
              onClick={() => setCurrentUser(index)}
              icon={user.bookingInventoryId === undefined ? <Add /> : <Done />}
            />
          ))}
      </Grid>
      {inputs.mode === "team" &&
        userInformation.pricingModels.includes(PricingModel.ENTERPRISE) && (
          <Grid container>
            <Grid item style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Button
                onClick={e => setAnchorElDevice(e.currentTarget)}
                variant="contained"
                color="primary"
                style={{ marginRight: 10, marginBottom: 5 }}
                startIcon={<FilterList />}
              >
                {t("Devices")}
              </Button>
            </Grid>
            <Grid item style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Button
                onClick={e => setAnchorElDeviceCategories(e.currentTarget)}
                variant="contained"
                color="primary"
                style={{ marginRight: 10, marginBottom: 5 }}
                startIcon={<FilterList />}
              >
                {t("Device Categories")}
              </Button>
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default FormWorkplaceTeamBookingChips;
