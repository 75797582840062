import { IZoneSchedule, IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IZoneInventory } from "../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useInventoryManagementActions } from "../../../../Hooks/useInventoryManagement";
import { useCategoryManagementActions } from "../../../../Hooks/useCategoryManagement";
import { ZoneInfoSidebar } from "./ZoneInfoSidebar";
import { ZoneServiceInfoSidebar } from "./ZoneServiceInfoSidebar";
import { SingleTableArrangement } from "../../../../Domain/Types/FloorPlan/TableArrangement.type";
import { useEquipmentManagementActions } from "../../../../Hooks/useEquipmentManagement";
import { WeekdayNames } from "../../../../Domain/Types/FloorPlan/WeekdayNames.type";

export interface ZoneInfoSidebarProps {
  height: number;
  zoneSchedules: IZoneSchedule[];
  floorPlan: IFloorPayload;
  isZnInvModalOpen: boolean;
  hasSiteId: boolean;
  setIsZnInvModalOpen: (o: boolean) => void;
  onClose: () => void;
  onClickDelete: (zoneIds: number[]) => void;
  onChangeZoneDescription: (text: string) => void;
  onChangeName: (zoneIds: number[], name: string) => void;
  onChangePerm: (
    zoneIds: number[],
    perms: {
      companies: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
      costCenterUnits: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
      users: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
    }
  ) => void;
  onChangeEnabledStatus: (ids: number[], enabled: boolean) => void;
  onChangeUserNumber: (ids: number[], userNumber: IZoneUserNumber) => void;
  onChangeConfService: (id: number, service: SingleTableArrangement[]) => void;
  updateZoneInventory: (zoneIds: number[], inventory: IZoneInventory) => void;
  inventoryManagement: useInventoryManagementActions;
  categoryManagement: useCategoryManagementActions;
  equipmentManagement: useEquipmentManagementActions;
  floorPlanName: string;
}

export function ZoneInfoSidebarSwitch({
  height,
  zoneSchedules,
  floorPlan,
  isZnInvModalOpen,
  hasSiteId,
  setIsZnInvModalOpen,
  onClose,
  onClickDelete,
  onChangeZoneDescription,
  onChangeName,
  onChangePerm,
  onChangeEnabledStatus,
  onChangeUserNumber,
  onChangeConfService,
  updateZoneInventory,
  inventoryManagement,
  categoryManagement,
  equipmentManagement,
  floorPlanName
}: ZoneInfoSidebarProps) {
  const isFloorPlanForService =
    !!floorPlan.tableArrangementAssignments && !!floorPlan.conferenceZoneLinks;

  return (
    <>
      {!isFloorPlanForService ? (
        <ZoneInfoSidebar
          updateZoneInventory={updateZoneInventory}
          height={height}
          zoneSchedules={zoneSchedules}
          floorPlan={floorPlan}
          inventoryManagement={inventoryManagement}
          categoryManagement={categoryManagement}
          equipmentManagement={equipmentManagement}
          isZnInvModalOpen={isZnInvModalOpen}
          hasSiteId={hasSiteId}
          floorPlanName={floorPlanName}
          setIsZnInvModalOpen={(o: boolean) => setIsZnInvModalOpen(o)}
          onClose={onClose}
          onClickDelete={onClickDelete}
          onChangeZoneDescription={onChangeZoneDescription}
          onChangeName={onChangeName}
          onChangeEnabledStatus={onChangeEnabledStatus}
          onChangePerm={onChangePerm}
          onChangeUserNumber={onChangeUserNumber}
          onChangeConfService={onChangeConfService}
        />
      ) : (
        <ZoneServiceInfoSidebar
          height={height}
          zoneSchedules={zoneSchedules}
          floorPlan={floorPlan}
          onClose={onClose}
          onChangeUserNumber={onChangeUserNumber}
          onChangeConfService={onChangeConfService}
        />
      )}
    </>
  );
}
