import { Box, Container } from "@mui/material";
import { useSelector } from "../../app/helpers";
import SvgDrawer from "./connected-drawer.component";
import FloorManagerNavigation from "./FloorManagerNavigation/floor-manager-navigation.component";
import SvgModalsContainer from "./svg-modals-container.component";
import { RootState } from "../../app/rootReducer";

/**
 * Wrapper to render Floor Manager, including navigation steps and floor plan view.
 */
const FloorManager: React.FC = () => {
  const { selected, activeStep } = useSelector((state: RootState) => state.floorManager);

  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 70px)",
        overflow: activeStep === 2 && selected.timeframe ? "inherit" : "auto"
      }}
      onMouseEnter={e => {
        e.currentTarget.tabIndex = 1;
        e.currentTarget.focus();
      }}
      data-testid="floor-manager-box"
    >
      <Container
        data-testid="floor-manager-container"
        maxWidth={false}
        sx={{
          padding: "0px !important",
          position: "relative",
          // overrride style
          maxWidth: selected.timeframe ? "calc(100%)" : "calc(100% - 135px)"
        }}
      >
        <SvgModalsContainer />
        <FloorManagerNavigation />
        <SvgDrawer />
      </Container>
    </Box>
  );
};

export default FloorManager;
