import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../../../Domain/Types/FloorPlan/Permission.type";

export const generateNewPerms = ({
  selectedCompanies = [],
  selectedCompanyUnits = [],
  selectedUsers = []
}: {
  selectedCompanies: RemoteCompanyPerm[];
  selectedCompanyUnits: RemoteCompanyUnitsPerm[];
  selectedUsers: RemoteUsersPerm[];
}) => {
  const newCompanies = [
    ...selectedCompanies.map(company => ({
      id: company.id.toString(),
      enforceUsage: company.enforceUsage,
      activeWeekdays: company.activeWeekdays
    }))
  ];

  const newCostCenterUnits = [
    ...selectedCompanyUnits.map(unit => ({
      id: unit.id,
      enforceUsage: unit.enforceUsage,
      activeWeekdays: unit.activeWeekdays
    }))
  ];

  const newUsers = [
    ...selectedUsers.map(user => ({
      id: user.userId,
      enforceUsage: user.enforceUsage,
      activeWeekdays: user.activeWeekdays
    }))
  ];

  const newPerms = {
    companies: newCompanies,
    costCenterUnits: newCostCenterUnits,
    users: newUsers
  };

  return newPerms;
};
