import { Point } from "pixi.js";
import { deskPatterns } from "../../../../Domain/Constants/DeskPatterns";
import { ICoordinate } from "../../../../Domain/Types/FloorPlan/Coordinate.type";
import { ITimeframe } from "../../../../Domain/Types/FloorPlan/Timeframe.type";
import Position from "../../../../Domain/Types/Position.type";
import { ToolSelection } from "../CreateFloorPlanView";
import { DateTime } from "luxon";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { PlaceVariant } from "../../../../Domain/Types/FloorPlan/PlaceVariant.type";

export function newDesk(
  selectedZoneId: number,
  selectedTimeframe: ITimeframe,
  placeOpts: {
    deskPos: Position;
    numberOfPlacesAdd: number;
    placeScale: number;
    typeOfPlaces: number;
  },
  addDesks: (
    opts: { start: string; end: string | null; selectedZoneId: number; placeTypeId: number },
    ...desks: Array<{ position: ICoordinate; rotate: number }>
  ) => void
) {
  const { deskPos, numberOfPlacesAdd, placeScale, typeOfPlaces } = placeOpts;
  const basicOpts = {
    start: selectedTimeframe.start,
    end: selectedTimeframe.end ?? null,
    selectedZoneId: selectedZoneId,
    placeTypeId: typeOfPlaces
  };

  const boundingBox = boundingBoxPerPlaceType({
    inventory: undefined,
    placeTypeId: typeOfPlaces
  } as IPlaceSchedule);

  const xGap = (boundingBox.width + 10) * placeScale;
  const yGap = (boundingBox.height + 60) * placeScale;

  const pattern = deskPatterns[numberOfPlacesAdd];
  const desks = [];
  const patternRows = pattern.length;
  for (let i = 0; i < patternRows; i++) {
    const desksPerRow = pattern[i].length;

    for (let j = 0; j < desksPerRow; j++) {
      if (pattern[i][j] === 1) {
        desks.push({
          position: { x: deskPos.x + j * xGap, y: deskPos.y + i * yGap },
          rotate: i === 0 ? 0 : 180
        });
      }
    }
  }

  addDesks(basicOpts, ...desks);
}

export function onClickPlace(
  deskId: number,
  tool: ToolSelection,
  selectDesk: (deskId: number) => void
) {
  if (tool === undefined) {
    selectDesk(deskId);
  }
}

interface OptsProps {
  start: string;
  end: string | null;
  selectedZoneId: number;
  placeTypeId: number;
}
interface DesksProps {
  position: ICoordinate;
  rotate: number;
}

export function handleClickedZone(
  tool: ToolSelection,
  selectedZoneId: number,
  selectedTimeframe: ITimeframe,
  placeOpts: {
    deskPos: Point | undefined;
    numberOfPlacesAdd: number;
    placeScale: number;
    typeOfPlaces: number;
  },
  addDesks: (opts: OptsProps, ...desks: DesksProps[]) => void,
  selectZone: (zoneId: number) => void
) {
  const { deskPos, numberOfPlacesAdd, placeScale, typeOfPlaces } = placeOpts;
  if (!deskPos) return;
  switch (tool) {
    case "DESK.NEW":
      newDesk(
        selectedZoneId,
        selectedTimeframe,
        { deskPos, numberOfPlacesAdd, placeScale, typeOfPlaces },
        addDesks
      );
      return;
    case undefined: {
      return selectZone(selectedZoneId);
    }
  }
}

export function checkIfIsEditable(timeframe: ITimeframe, isFloorPlanForService: boolean) {
  if (isFloorPlanForService) return true;
  const todayDate = DateTime.local();

  const startDate = DateTime.fromISO(timeframe.start);
  const endDate = DateTime.fromISO(timeframe.end || "");

  return startDate > todayDate && (!endDate.isValid || endDate > todayDate);
}

export function checkToolAndMouseOverZone(
  tool: ToolSelection,
  pointer: "Enter" | "Leave",
  setIsMouseOverZone: (m: boolean) => void
) {
  let result = undefined;

  if (tool === undefined) return result; // prohibits re-renders when trying to move desks

  if (pointer === "Enter") {
    result = true;
    setIsMouseOverZone(result);
  } else if (pointer === "Leave") {
    result = false;
    setIsMouseOverZone(result);
  }

  return result;
}

export function checkToolSelection(
  tool: ToolSelection,
  setBlockViewportMovement: (b: boolean) => void
) {
  if (tool === undefined) {
    setBlockViewportMovement(false);
  }
}

export function checkPlaceDisabledStatus(disabled: boolean) {
  return disabled ? PlaceVariant.WEAK : PlaceVariant.AVAILABLE;
}

// check every places and zones have both inventory & category
export function checkIfPublishIsAvailable(
  currentFloorPlan: IFloorPayload,
  setEnablePublish: (e: boolean) => void
): void {
  const wpInv = currentFloorPlan.places.map(w => w.inventory?.id);
  const znInv = currentFloorPlan.zones.map(z => z.inventory?.id);
  const wpCat = currentFloorPlan.places.map(w => w.category?.id);
  const znCat = currentFloorPlan.zones.map(z => z.category?.id);

  const checkUndefined = (items: any) => items.some((i: any) => i === undefined);

  const result =
    checkUndefined(wpInv) ||
    checkUndefined(znInv) ||
    checkUndefined(wpCat) ||
    checkUndefined(znCat);

  if (!result) setEnablePublish(false);
  else setEnablePublish(true);
}

export function checkDeskPos(deskPos: Point | undefined) {
  if (!deskPos) {
    return console.error("No Desk Position determined, is the viewport initialized?");
  }
}

export function boundingBoxPerPlaceType(place: IPlaceSchedule): { width: number; height: number } {
  // bounding box
  const normalWp = { width: 160, height: 80 };
  const parkingPl = { width: 200, height: 400 };
  const eParkingPl = { width: 200, height: 400 };
  const canteenPl = { width: 160, height: 80 };

  if (place.inventory && place.inventory.boundingBox) {
    return place.inventory.boundingBox;
  }

  switch (place.placeTypeId) {
    case 1: // normal workplace
      return normalWp;
    case 2: // parking place
      return parkingPl;
    case 3: // canteen place
      return canteenPl;
    case 4: // e-car charging parking place
      return eParkingPl;
    default:
      return normalWp;
  }
}

export function lengthPerPlaceType(places: IPlaceSchedule[]): { rec: number; pos: number } {
  let mostWidth: number = 0;
  let mostHeight: number = 0;

  const { width, height } = boundingBoxPerPlaceType(places[0]);
  mostWidth = width;
  mostHeight = height;

  for (const place of places) {
    const { width: nextWidth, height: nextHeight } = boundingBoxPerPlaceType(place);

    if (nextWidth > mostWidth) mostWidth = nextWidth;
    if (nextHeight > mostHeight) mostHeight = nextHeight;
  }

  const most = mostWidth > mostHeight ? mostWidth : mostHeight;
  return { rec: most, pos: most / 2 };
}
