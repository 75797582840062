import { FileObject } from "react-mui-dropzone";
import { homeOfficeAppendMax, homeOfficeAppendMin, idAppend } from "../../meta-admin.functions";
import { CheckInSettings, AdvancedBookingSettings } from "../../../Login/typings/login.types";

/** raw company to be converted into a form data object */
interface RawLocationData {
  /** true, if the creating user is administrator */
  isTenantAdmin: boolean;
  /** The theme color of the company, to be displayed as primary color in mui components */
  brandColor: string;

  /** if enabled, shows schedule data between colleagues */
  showPastScheduleDataOfColleagues: boolean;
  /** if enabled, allows to make bookings on saturdays */
  bookingsOnSaturdaysAllowed: boolean;
  /** if enabled, allows to make bookings on sundays */
  bookingsOnSundaysAllowed: boolean;
  /** if enabled, allows to calendar sync integration  */
  calendarSyncPossible: boolean;
  /** if enabled, allows booking days as home office */
  homeOffice: {
    homeOfficeDays: [number, number];
    enabled: boolean;
    mandatory: boolean;
  };
  /** logo image for the company */
  logoFile?: FileObject[];
  /** the count of hours selected by default when checking in, should be 8 */
  bookingNormHours: number;
  /** */
  automatedWorkplaceBookingReflectionTime: number;
  /** */
  noBookingTime: number;
  /** company booking meta id */
  id: number;
  /** only set to update a company after creating one */
  createdCompanyId?: number;
  bookingAssistenceActive: boolean;
  /** check-in settings */
  checkinSettings?: Array<CheckInSettings>;
  /** maximum advanced booking time settings  */
  advancedBookingSettings: Array<AdvancedBookingSettings>;
}

/**
 * takes mutation results and converts them to form data
 * @param dataAxios
 * @param isTenantAdmin
 * @param brandColor
 * @param showPastScheduleDataOfColleagues
 * @param bookingsOnSaturdaysAllowed
 * @param bookingsOnSundaysAllowed
 * @param homeOffice
 * @param logoFile
 * @param bookingNormHours
 * @param automatedWorkplaceBookingReflectionTime
 * @param noBookingTime
 * @param id
 * @param checkinSettings
 * @param advancedBookingSettings
 */
export function createFormMetadata({
  brandColor,
  showPastScheduleDataOfColleagues,
  bookingsOnSaturdaysAllowed,
  bookingsOnSundaysAllowed,
  calendarSyncPossible,
  homeOffice,
  logoFile,
  bookingNormHours,
  automatedWorkplaceBookingReflectionTime,
  noBookingTime,
  id,
  createdCompanyId,
  bookingAssistenceActive,
  checkinSettings,
  advancedBookingSettings
}: RawLocationData): FormData {
  const data = new FormData();

  data.append("companyId", `${createdCompanyId}`);
  data.append("homeOfficePolicyObligatory", JSON.stringify(homeOffice.mandatory));
  data.append("mobileWorkingEnabled", String(homeOffice.enabled));
  data.append("bookingsAreAnonymous", "true");
  data.append("color", brandColor);

  logoFile && data.append("file", logoFile[0].file);

  data.append("bookingNormHours", String(bookingNormHours));
  data.append(
    "automatedWorkplaceBookingReflectionTime",
    String(automatedWorkplaceBookingReflectionTime)
  );
  data.append("showPastScheduleDataOfColleagues", String(showPastScheduleDataOfColleagues));
  data.append("bookingsOnSaturdaysAllowed", String(bookingsOnSaturdaysAllowed));
  data.append("bookingsOnSundaysAllowed", String(bookingsOnSundaysAllowed));
  data.append("calendarSyncPossible", String(calendarSyncPossible));

  homeOfficeAppendMin(homeOffice, data);
  homeOfficeAppendMax(homeOffice, data);

  data.append("id", idAppend(id));
  data.append("maxAllowedNoBookingTime", String(noBookingTime));
  data.append("bookingAssistenceActive", String(bookingAssistenceActive));
  data.append("checkinSettings", JSON.stringify(checkinSettings));
  data.append("advancedBookingSettings", JSON.stringify(advancedBookingSettings));

  return data;
}
