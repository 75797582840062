import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceInventory } from "../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { useCategoryManagementActions } from "../../../../Hooks/useCategoryManagement";
import { useEquipmentManagementActions } from "../../../../Hooks/useEquipmentManagement";
import { useInventoryManagementActions } from "../../../../Hooks/useInventoryManagement";
import { useShowMultiPlacesItem } from "../../../../Hooks/useShowMultiPlacesItems";
import {
  Stretch,
  Transform
} from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

import { InfoSideBar } from "../InfoSidebar";
import { PlaceInfoBox } from "./PlaceInfoBox";
import { WeekdayNames } from "../../../../Domain/Types/FloorPlan/WeekdayNames.type";

interface Props {
  height: number;
  placeSchedules: IPlaceSchedule[];
  floorPlan: IFloorPayload;
  locationInventoryId: number;
  inventoryManagement: useInventoryManagementActions;
  categoryManagement: useCategoryManagementActions;
  equipmentManagement: useEquipmentManagementActions;
  floorPlanName: string;
  onChangeName: (deskIds: number[], name: string) => void;
  onChangeTransform: (deskIds: number[], t: Transform) => void;
  onChangeStretch: (deskIds: number[], t: Stretch) => void;
  onChangeEnabledStatus: (deskIds: number[], enabled: boolean) => void;
  onChangeDescription: (deskIds: number[], text: string) => void;
  onChangePerm: (
    placeIds: number[],
    perms: {
      companies: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
      costCenterUnits: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
      users: { id: string; enforceUsage: boolean; activeWeekdays: WeekdayNames[] }[];
    }
  ) => void;
  onClickDelete: (deskIds: number[]) => void;
  onCloseDesk: () => void;
  updatePlaceInventory: (placeIds: number[], inventory: IPlaceInventory) => void;
}

export function MultiPlaceInfoSideBar({
  placeSchedules,
  height,
  floorPlan,
  locationInventoryId,
  inventoryManagement,
  categoryManagement,
  equipmentManagement,
  floorPlanName,
  onChangeTransform,
  onChangeStretch,
  onClickDelete,
  onCloseDesk,
  onChangeName,
  onChangeDescription,
  onChangeEnabledStatus,
  onChangePerm,
  updatePlaceInventory
}: Props) {
  const [showMultiPlacesEdit, setShowMultiPlacesEdit] = useState(false);

  const selectedIds = placeSchedules.map(m => m.id);
  const { showPlaceEditTooltip, showPlaceEditIcon, infoSideBarTheme, useToggleButton } =
    useShowMultiPlacesItem(showMultiPlacesEdit);

  useEffect(() => {
    checkPlaceSchedulesLength(placeSchedules.length, setShowMultiPlacesEdit);
  }, [placeSchedules]);

  return (
    <InfoSideBar height={height} width={250} overflow={infoSideBarTheme.overflow}>
      {!!placeSchedules.length && (
        <PlaceInfoBox
          color={infoSideBarTheme.color}
          key={placeSchedules[0].id}
          floorPlan={floorPlan}
          locationInventoryId={locationInventoryId}
          onClose={onCloseDesk}
          inventoryManagement={inventoryManagement}
          categoryManagement={categoryManagement}
          equipmentManagement={equipmentManagement}
          updatePlaceInventory={updatePlaceInventory}
          onChangeTransform={t => onChangeTransform(selectedIds, t)}
          onChangeStretch={t => {
            onChangeStretch(selectedIds, t);
          }}
          onClickDelete={() => {
            onClickDelete(selectedIds);
          }}
          onChangeName={name => onChangeName(selectedIds, name)}
          onChangeDescription={text => onChangeDescription(selectedIds, text)}
          onChangeEnabledStatus={status => onChangeEnabledStatus(selectedIds, status)}
          showMultiPlacesEdit={showMultiPlacesEdit}
          placeSchedules={placeSchedules}
          onChangePerm={newPerms => onChangePerm(selectedIds, newPerms)}
          floorPlanName={floorPlanName}
        />
      )}

      {/* bottom icon to toggle editing multi places or each single places, not used currently */}
      {useToggleButton && (
        <IconButton
          data-testid="toggle-btn-multiplaces-edit"
          sx={{
            position: "relative",
            width: 45,
            top: -5,
            left: 185,
            m: 1,
            background: infoSideBarTheme.color
          }}
          onClick={() => setShowMultiPlacesEdit(!showMultiPlacesEdit)}
          size="large"
        >
          <Tooltip title={showPlaceEditTooltip}>{showPlaceEditIcon}</Tooltip>
        </IconButton>
      )}
    </InfoSideBar>
  );
}

export function checkPlaceSchedulesLength(
  length: number,
  setShowMultiPlacesEdit: (s: boolean) => void
) {
  if (length === 1) setShowMultiPlacesEdit(false);
  else setShowMultiPlacesEdit(true);
}
