import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { Point } from "pixi.js";
import { Graphics, Sprite } from "@pixi/react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { IViewport } from "../Domain/Types/FloorPlan/Viewport.type";
import DemoFloorPlan from "./sample-fk-secondary.json";
import { useBackgroundImage } from "../Hooks/useBackgroundImage";
import { DisplayViewport } from "../Components/DisplayViewport/DisplayViewport";
import { PlaceContainer } from "../Components/Place/PlaceContainer";
import { ZoneContainer } from "../Components/Zone/ZoneContainer";
import { useGeometry } from "../Hooks/useGeometry";
import { PlaceVariant } from "../Domain/Types/FloorPlan/PlaceVariant.type";

export type ViewportScalerProps = {
  /** Floor outline image url. */
  imageUrl: string;
  /** Initial viewport of floor */
  initialViewport: IViewport;

  onChange?: (result: IViewport) => void;
};

export const ViewportScaler: React.FC<ViewportScalerProps> = ({
  imageUrl,
  initialViewport,
  onChange
}) => {
  const { t } = useTranslation();
  const { convertPixiPoints } = useGeometry();

  const { background } = useBackgroundImage({
    backgroundImageUrl: imageUrl,
    viewport: initialViewport
  });

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / initialViewport.width);
  }, [background, initialViewport]);

  const [viewportChanges, setViewportChanges] = useState<IViewport>({
    width: initialViewport.width,
    height: initialViewport.height
  });

  const [calc] = useState({
    hRatio: initialViewport.height / initialViewport.width
  });

  // Amount to change viewport by with each step
  const stepAmount = 20;
  const textFieldStyle = {
    width: "7rem",
    margin: "0 3px",
    backgroundColor: "#f0f0f0",
    opacity: 0.9,
    borderRadius: 3
  };

  const handleChangePlus = () => {
    const modifiedWidth = (initialViewport.width += stepAmount);
    const modifiedHeight = (initialViewport.height += Math.round(stepAmount * calc.hRatio));
    const result = {
      width: modifiedWidth,
      height: modifiedHeight
    };
    onChange?.(result);
    setViewportChanges(result);
  };
  const handleChangeMinus = () => {
    const modifiedWidth = (initialViewport.width += -stepAmount);
    const modifiedHeight = (initialViewport.height += -Math.round(stepAmount * calc.hRatio));
    const result = {
      width: modifiedWidth,
      height: modifiedHeight
    };
    onChange?.(result);
    setViewportChanges(result);
  };
  const handleInterval = (cb: () => void) => {
    cb(); // call so it behaves like click when mousing up immediately
    const interval = window.setInterval(cb, 100);
    document.addEventListener("mouseup", () => {
      window.clearInterval(interval);
    });
  };

  return (
    <>
      {!background && <CircularProgress />}
      {background && (
        <DisplayViewport
          floorPlanName={"floor frame change"}
          initialZoomEnd={new Point(background.width, background.height)}
          currentFloorPlan={undefined}
          viewportDimensions={viewportDimensions}
          setViewportDimensions={setViewportDimensions}
        >
          <Sprite
            texture={background}
            width={viewportChanges.width}
            height={viewportChanges.height}
          />

          {/* outline of the background */}
          <Graphics
            draw={g => {
              g.clear();
              g.lineStyle(2, 0x000000, 1);
              g.drawRect(0, 0, viewportChanges.width, viewportChanges.height);
              g.endFill();
            }}
          />

          {/** draw zones */}
          {DemoFloorPlan.zones.map(zone => (
            <ZoneContainer
              key={zone.id}
              id={zone.id}
              inventoryId={zone.inventoryId}
              walls={convertPixiPoints(zone.coordinates)}
              zoneType={1}
            />
          ))}

          {/** draw places */}
          {DemoFloorPlan.places.map(workplace => (
            <PlaceContainer
              key={workplace.id}
              boundingBox={{
                width: workplace.inventory.boundingBox.width,
                height: workplace.inventory.boundingBox.height
              }}
              id={workplace.id}
              inventoryId={workplace.inventoryId}
              rotation={workplace.rotate}
              position={workplace.position}
              variant={PlaceVariant.AVAILABLE}
              isSelectable={false}
              placeTypeId={1}
              placeScale={placeScale}
            />
          ))}
        </DisplayViewport>
      )}

      <Grid
        data-testid={"frame-handle-container"}
        container
        sx={{ position: "absolute", top: "12%", left: "3%", width: "90%" }}
      >
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column" }}
          data-testid={"frame-handle-btn"}
        >
          <IconButton
            data-testid="btn-handle-plus"
            onMouseDown={() => handleInterval(handleChangePlus)}
            size="large"
          >
            <AddBox fontSize="large" sx={{ color: "black" }} />
          </IconButton>
          <IconButton
            data-testid="btn-handle-minus"
            onMouseDown={() => handleInterval(handleChangeMinus)}
            size="large"
          >
            <IndeterminateCheckBox fontSize="large" sx={{ color: "black" }} />
          </IconButton>
        </Grid>
        <Grid item data-testid={"frame-handle-textfield"}>
          <Box>
            <TextField
              sx={textFieldStyle}
              size="small"
              data-testid="viewport-scaler-width"
              label={t("Width")}
              value={viewportChanges.width}
              InputProps={{
                style: { color: "black" },
                readOnly: true,
                endAdornment: <Icon icon={"radix-icons:width"} width={50} height={50} />
              }}
              InputLabelProps={{
                style: { color: "black" }
              }}
              variant="filled"
            />
            <TextField
              sx={textFieldStyle}
              size="small"
              data-testid="viewport-scaler-height"
              label={t("Height")}
              value={viewportChanges.height}
              InputProps={{
                style: { color: "black" },
                readOnly: true,
                endAdornment: <Icon icon={"radix-icons:height"} width={50} height={50} />
              }}
              InputLabelProps={{
                style: { color: "black" }
              }}
              variant="filled"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
