import React, { useState } from "react";
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight, Close, Menu } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import RLELogo from "../../../app/logo/rle-icon-white.png";
import AccountDropdown from "../../UserAccount/account-menu.component";
import { HoverMenuCard, HoverMenuState } from "../components/hover-menu.component";
import { ResponsiveDrawerItems } from "../components/responsive-drawer-items.component";
import { DrawerContext } from "../responsive-drawer-context";
import { ThemeToggleButton } from "../ThemeToggleButton/ThemeToggleButton";
import { Offset } from "./responsive-drawer-offset.component";

/**
 * @description Menu used for the app. Containing a mobile and desktop variant. Hidden by MUI breakpoints.
 * @param props.children as a React.Node containing the inner components.
 * @version 0.2.0
 */
type P = {
  children: React.ReactNode;
};

export const ResponsiveDrawer: React.FC<P> = props => {
  const { t } = useTranslation();
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hoverMenu, setHoverMenu] = useState<HoverMenuState>({
    isOpen: false,
    anchorElId: null,
    anchorEl: null,
    top: null
  });

  const toggleDrawerMobile = () => setMobileOpen(p => !p);
  const openDrawer = () => setIsOpen(!isOpen);

  const miniDrawerCommonSxProps = {
    width: isOpen ? 250 : theme.spacing(7),
    overflowX: "hidden",
    paddingBottom: "50px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: isOpen
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen
    })
  };

  return (
    <Box sx={{ display: "flex" }} data-testid="responsive-drawer-parent-box">
      <DrawerContext.Provider
        value={{
          isOpen: isOpen,
          hoverMenu,
          setHoverMenu
        }}
      >
        <HoverMenuCard
          state={hoverMenu}
          onClose={() => {
            setHoverMenu({
              isOpen: false,
              anchorElId: null,
              anchorEl: null,
              top: null
            });
          }}
        />
        <AppBar
          position="fixed"
          color="primary"
          enableColorOnDark
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            [theme.breakpoints.up("sm")]: {
              width: `100%`
            }
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <IconButton
                sx={{
                  paddingRight: "5px",
                  marginRight: theme.spacing(2),
                  [theme.breakpoints.up("sm")]: {
                    display: "none"
                  }
                }}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawerMobile}
                size="large"
              >
                {mobileOpen ? (
                  <Close sx={{ fill: theme.palette.text.primary }} />
                ) : (
                  <Menu sx={{ fill: theme.palette.text.primary }} />
                )}
              </IconButton>
              <Link to={"/"}>
                <Box
                  data-testid="drawer-logo-link"
                  sx={{
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    margin: "5px 0 5px -5px",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${metaData?.logoPath || RLELogo})`,
                    width: 140,
                    height: 50,
                    display: "flex",
                    padding: theme.spacing(2),
                    zIndex: 1222,
                    [theme.breakpoints.down("md")]: { paddingTop: "10px" }
                  }}
                />
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "90px" // with notification icon, 140px
              }}
            >
              <ThemeToggleButton />
              {/* <NotificationIconButton /> -- currently we don't use it -- */}

              <AccountDropdown />
            </div>
          </Toolbar>
        </AppBar>
        {metaData && (
          <nav
            data-testid={"drawer-nav"}
            // style={{ [theme.breakpoints.up("sm")]: { flexShrink: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              data-testid="smUp-drawer"
              sx={{ display: { xs: "block", sm: "none" } }}
              PaperProps={{ sx: { width: 250, overflowX: "hidden" } }}
              variant="persistent"
              open={mobileOpen}
              onClose={toggleDrawerMobile}
              ModalProps={{
                keepMounted: true,
                onBackdropClick: toggleDrawerMobile
              }}
            >
              <ResponsiveDrawerItems onToggleDrawerMobile={toggleDrawerMobile} isOpen={isOpen} />
            </Drawer>

            {/* Implementation of Mini variant drawer */}
            <Drawer
              data-testid="smDown-drawer"
              variant="permanent"
              sx={{ display: { xs: "none", sm: "block" }, ...miniDrawerCommonSxProps }}
              PaperProps={{ sx: { ...miniDrawerCommonSxProps } }}
            >
              <ResponsiveDrawerItems onToggleDrawerMobile={toggleDrawerMobile} isOpen={isOpen} />
              <IconButton
                data-testid={"open-responsive-drawer-btn"}
                onClick={openDrawer}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    display: "none"
                  },
                  background: theme.palette.background.paper,
                  position: "fixed",
                  bottom: "0px",
                  left: "0px",
                  width: !isOpen ? theme.spacing(7) : "249px",
                  zIndex: 10000,
                  display: "flex",
                  justifyContent: "end",
                  borderRadius: "0",
                  transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                  }),
                  "&:hover": {
                    background: theme.palette.background.paper
                  }
                }}
                size="large"
              >
                {!isOpen ? (
                  <ChevronRight sx={{ fill: theme.palette.text.primary }} />
                ) : (
                  <>
                    <ChevronLeft sx={{ fill: theme.palette.text.primary }} />
                    <Typography sx={{ fontSize: 16, color: theme.palette.text.primary }}>
                      {t("Collapse")}
                    </Typography>
                  </>
                )}
              </IconButton>
            </Drawer>
          </nav>
        )}

        <main
          style={{
            flexGrow: 1,
            width: `100%`,
            minHeight: `100vh`,
            overflow: "hidden"
          }}
        >
          {/* previsouly, had paddingTop: 6px */}
          <Offset />
          {props.children}
        </main>
      </DrawerContext.Provider>
    </Box>
  );
};

export default ResponsiveDrawer;
