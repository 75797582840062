import { BookingType } from "../../Booking-Form/typings/booking-inputs";

export type PlaceType = {
  id: number;
  name: string;
  appearance: {
    boundingBox: { width: number; height: number };
  };
};

export type ZoneType = {
  id: number;
  name: string;
  appearance: {
    fillHex: string;
    iconSlug: string;
    strokeHex: string;
  };
};

export type CheckinAndMaxAdvancedBooking = CheckInSettings & AdvancedBookingSettings;

export type AdvancedBookingSettings = {
  maxWeeksInAdvance: number | undefined;
  bookingType: BookingType;
};

export type CheckInSettings = {
  checkinBufferTime: number;
  geoLocationRequired: boolean;
  checkinWhenOpeningDoor: boolean;
  bookingType: BookingType;
};

export type UserInformation = {
  firstName: string;
  department: string;
  surname: string;
  email: string;
  username: string;
  automatedBookingActivated: boolean;
  placeTypes: PlaceType[];
  zoneTypes: ZoneType[];
  sub: string;
  resourceId: string;
  isLocationAdmin: boolean;
  company: {
    meta: MetaData;
  };
  photoUrl: string | undefined;
  settings: any;
  companyId: number;
  pricingModels: PricingModel[];
  exp?: number;
  iat?: number;
  msGraphEnabled: boolean;
};

/** related with license size */
export enum PricingModel {
  FREEMIUM = "FREEMIUM",
  GROWTH = "GROWTH",
  ENTERPRISE = "ENTERPRISE"
}

/** Metadata of a company that contains numerous flags and information */
export interface MetaData {
  /** the currency that is used when calcualting prices for a company */
  currency: string;
  /** default time zone of the company */
  defaultTimezone: string;
  /** the user can only book this many days in advance for zones */
  maxAdvancedZoneBookingLength: number;
  /** a minimum amount of mobile working days the user has to book */
  minMobileWorkingDays: number;
  /** a maximum amount of mobile working days the user has to book */
  maxMobileWorkingDays: number;
  /** check in settings(buffer time, geoLocation required, when opening door) per booking type */
  checkinSettings: Array<CheckInSettings>;
  /** how many hours a user may book per day */
  maxAllowedNoBookingTime: number;
  /** default samount of hours selected when trying to book */
  bookingNormHours?: number;
  /** maximum amount of hours a user can book */
  maxAdvancedWorkplaceBookingLength?: number;
  /** advanced booking settings */
  advancedBookingSettings?: Array<AdvancedBookingSettings>;
  /** if active, a user can book on sundays */
  bookingsOnSundaysAllowed?: boolean;
  /** if active, a user can book on saturdays */
  bookingsOnSaturdaysAllowed?: boolean;
  /** company id */
  companyId?: number;
  /** id of the metadata entity */
  id?: number;
  /** a HEX-Color */
  color?: string | null;
  /** URI to a logo image file */
  logoPath?: string | null;
  /** whether the user is allowed to book mobile working */
  mobileWorkingEnabled: boolean;
  /** whether the user has to book an amount of mobile days */
  homeOfficePolicyObligatory: boolean;
  /** whether to show the booking assistance or not */
  bookingAssistenceActive: boolean;
  /** whether to allow calendar integrations */
  calendarSyncPossible: boolean;
}

export interface UserRolesFlags {
  isTenantAdmin: boolean;
  isCompanyAdmin: boolean;
  isLocationAdmin: boolean;
  isHrAdmin: boolean;
  isFinanceAdmin: boolean;
  isDeviceResponsible: boolean;
  isProjectResponsible: boolean;
  isWorkOrderResponsible: boolean;
  isCostCenterResponsible: boolean;
  isCapacityResponsible: boolean;
  isEmployeeResponsible: boolean;
  isZoneBookingCapable: boolean;
}
