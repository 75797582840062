import { checkTimeframesOverlap, Timeframe } from "timeframes";
import { useDispatch, useSelector } from "../../app/helpers";
import InitializingLoadingScreenModal from "./partials/initializing-loadingscreen.component";
import selectedEntitiesSelector from "./selectors/selectedEntitiesSelector";
import { DerivedSelectedState, unselect } from "./slices/selected.slice";
import { setStep } from "./slices/steps.slice";
import { RootState } from "../../app/rootReducer";
import { CreateFloorPlanView } from "../../components/FacilityManager/Components/Views/CreateFloorPlanView/CreateFloorPlanView";
import { usePayload } from "../../components/FacilityManager/Hooks/usePayload";
import { ITimeframe } from "../../components/FacilityManager/Domain/Types/FloorPlan/Timeframe.type";

const ConnectedDrawer: React.FC = () => {
  const dispatch = useDispatch();

  const selected = useSelector(selectedEntitiesSelector);
  const plans = useSelector((store: RootState) => store.floorManager.plans);
  const selectedPlan = getSelectedPlan(selected, plans);
  const outlineUrl = getOutlineUrl(selected);

  const {
    handlePublish,
    isFloorPlanLoading: isPublishing,
    isInitialized,
    doRemount,
    floorPlanUpdateStatus
  } = usePayload({
    timeframe: selected.timeframe as ITimeframe,
    floorInventoryId: selected.floor?.id ?? 0
  });

  if (!selected.floor?.id || !selected.timeframe?.start || !outlineUrl || !selected.location?.id)
    return null;

  return (
    <>
      {!doRemount && (
        <>
          <CreateFloorPlanView
            width={1200}
            height={800}
            name={selected.floor?.name}
            floorPlan={selectedPlan}
            backgroundImageUrl={outlineUrl}
            onPublish={floorPlan => handlePublish(floorPlan)}
            onAbort={() => {
              dispatch(unselect("timeframe"));
              dispatch(setStep(2));
            }}
            selectedTimeframe={selected.timeframe}
            selectedFloorId={selected.floor.id}
            locationInventoryId={selected.location?.id}
            floorPlanUpdateStatus={floorPlanUpdateStatus}
          />
        </>
      )}
      <InitializingLoadingScreenModal isOpen={isPublishing} title={isInitialized} />
    </>
  );
};

export default ConnectedDrawer;

export function getOutlineUrl(selected: DerivedSelectedState) {
  return (
    selected.floor &&
    selected.timeframe &&
    (selected.floor.schedule.find(s => checkTimeframesOverlap(s, selected.timeframe as Timeframe))
      ?.outlinePath ||
      selected.floor.schedule[0].outlinePath)
  );
}

export function getSelectedPlan(selected: DerivedSelectedState, plans: any) {
  return selected.floor?.id && plans[selected.floor.id];
}
