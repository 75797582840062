import React, { useEffect } from "react";
import { Application, Point, Container, DisplayObject, Rectangle } from "pixi.js";
import { PixiComponent, useApp } from "@pixi/react";
import { Viewport as RawViewport } from "pixi-viewport";
import { IFloorPayload } from "../../Domain/Types/FloorPlan/FloorPayload.type";
import { ICoordinate } from "../../Domain/Types/FloorPlan/Coordinate.type";
import { IViewport } from "../../Domain/Types/FloorPlan/Viewport.type";
import { PixiViewportPatch } from "./PixiViewoportPatch";

export interface ViewportProps {
  children?: React.ReactNode;
  initialZoomEnd: Point;
  onClickViewport?: (point: Point) => void;
  width: number;
  height: number;
  blockViewportMovement?: boolean;
  currentFloorPlan: IFloorPayload | undefined;
  onInit?: (viewport: RawViewport) => void;
  onExtract?: (instance: HTMLImageElement) => void;
  isExtractingCanvas?: boolean;
  initialPos?: ICoordinate;
  zoomToHighlight?: boolean;
}

export interface PixiComponentViewportProps extends ViewportProps {
  app: Application;
}

export type Opts = {
  initCorner: Point;
  onClickViewport?: (point: Point) => void;
  onInit?: (viewport: RawViewport) => void;
  initialPos?: ICoordinate;
};

const createPixiViewport = (opts: Opts) =>
  PixiComponent<PixiComponentViewportProps, RawViewport>("Viewport", {
    create: (props: PixiComponentViewportProps) => {
      // in order to apply eventSystem on the viewport
      props.app.renderer.events.domElement = props.app.renderer.view as any;

      const viewport = new PixiViewportPatch({
        ticker: props.app.ticker,
        passiveWheel: false,
        events: props.app.renderer.events
      });

      // adjust clamp zoom level according to the floor plan viewport size
      const ratio = adjustClampRatio(props.currentFloorPlan?.viewport, viewport);

      // decelerate is removed because of the performance
      viewport.drag().pinch().wheel().clampZoom({ minScale: 0.01, maxScale: ratio });

      // ensures coordinates are correct when drawing on the canvas
      viewport.on("clicked", e => {
        opts.onClickViewport?.(new Point(e.world.x, e.world.y));
      });

      return viewport;
    },
    /**
     * @implements later, if necessary, the background and pixi canvas are not removed properly while selecting other floors
     * need to implement this below
     * config: {
     *   destroy: true, // we don't want to auto destroy the instance on unmount
     *   destroyChildren: true // we also don't want to destroy its children on unmount
     *  },
     */
    didMount: (instance: RawViewport) => {
      opts.onInit?.(instance);

      const bounds = calculateSceneBounds(instance);
      instance.moveCenter(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
      instance.setZoom(
        getZoomScale(bounds, { width: instance.screenWidth, height: instance.screenHeight }),
        true
      );
    },
    applyProps: (instance: RawViewport, oldProps: PixiComponentViewportProps, newProps) => {
      applyPropsWithOpts(instance, oldProps, newProps, opts);
    },
    willUnmount: (instance: PixiViewportPatch) => {
      // workaround because the ticker is already destroyed by this point by the stage
      instance.options.noTicker = true;
      // workaround DOMElement: the domElement has been removed from events before willUnmount()
      instance.patchEvents();
      // instance.destroy({ children: true, texture: true }); // baseTexture: true
      // workaround DOMElement: restore changes after patch
      instance.releaseDOMElement();
    }
  });

const Viewport = (props: ViewportProps) => {
  const app = useApp();

  app.renderer.options.antialias = false;
  app.renderer.options.premultipliedAlpha = false;

  /**
   * it has a performance issue and firstly they capture the initial state of floor plan
   * which has only background and zone except places
   * later, when we have any changes on the currentFloorPlan then it updates the props
   * however, they also capture select rectangle as well
   */
  const asyncExtract = async (appStage: Container<DisplayObject>) => {
    const img = await app.renderer.extract.image(appStage);
    return img;
  };

  useEffect(() => {
    // capture the current status of the floor plan(canvas)
    if (props.currentFloorPlan === undefined) return;

    if (props.isExtractingCanvas) {
      asyncExtract(app.stage).then(img => {
        props.onExtract?.(img);
      });
    }
  }, [props.isExtractingCanvas, props.currentFloorPlan]);

  const PixiViewport = createPixiViewport({
    initCorner: props.initialZoomEnd,
    onClickViewport: props.onClickViewport,
    onInit: props.onInit,
    initialPos: props.initialPos
  });
  return <PixiViewport app={app} data-testid="pixi-viewport" {...props} />;
};

export default Viewport;

function zoomOut(
  instance: RawViewport,
  dimensions: { width: number; height: number },
  initialPos?: ICoordinate
) {
  if (!initialPos) return;

  const bounds = calculateSceneBounds(instance);

  instance.moveCenter(initialPos.x, initialPos.y);
  instance.animate({
    scale: getZoomScale(bounds, dimensions),
    time: 2200,
    ease: "easeInOutQuart",
    removeOnInterrupt: true,
    position: new Point(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2)
  });
}

export function applyPropsWithOpts(
  instance: RawViewport,
  oldProps: PixiComponentViewportProps, // ViewportProps,
  newProps: ViewportProps,
  opts: Opts
) {
  {
    if (newProps.width === 0 || newProps.height === 0) return;
    // when height and width of the viewport change, manually update the instance
    // if more props will be added, consider adding a comparision between old and new dimensions
    if (newProps.width !== oldProps.width || newProps.height || oldProps.height) {
      instance.resize(newProps.width, newProps.height);

      if (resizeIsNeeded(oldProps, newProps, opts.initialPos)) {
        const bounds = calculateSceneBounds(instance);
        instance.moveCenter(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        instance.setZoom(getZoomScale(bounds, newProps), true);
      }
    }

    // this prohibits some race condition that sets the viewport to 0.
    if (newProps.zoomToHighlight) {
      if (!opts.initialPos) {
        return;
      }
      instance.animate({
        scale: 0.8,
        time: 2200,
        ease: "easeInOutQuart",
        removeOnInterrupt: true,
        position: new Point(opts.initialPos.x, opts.initialPos.y)
      });
    } else {
      zoomOut(instance, newProps, opts.initialPos);
    }
  }

  // manually pause and resume an instance when required
  instance.pause = newProps.blockViewportMovement ?? false;
}

export function calculateSceneBounds(object: Container) {
  let bounds = object.getLocalBounds();

  for (const child of object.children) {
    if (child instanceof Container) {
      if (child.name != "place-box") {
        const childBounds = calculateSceneBounds(child as Container);
        bounds = new Rectangle(
          Math.min(bounds.x, childBounds.x),
          Math.min(bounds.y, childBounds.y),
          Math.max(bounds.width, childBounds.x + childBounds.width - bounds.x),
          Math.max(bounds.height, childBounds.y + childBounds.height - bounds.y)
        );
      }
    }
  }

  return bounds;
}

export function getZoomScale(
  renderedContentDimensions: { width: number; height: number },
  canvasDimensions: { width: number; height: number }
): number {
  let scale = 0;

  const renderedContentAspectRatio =
    renderedContentDimensions.width / renderedContentDimensions.height;
  const viewportAspectRatio = canvasDimensions.width / canvasDimensions.height;

  if (renderedContentAspectRatio > viewportAspectRatio) {
    // Rendered content is more landscape than the viewport, so fit by width
    scale = canvasDimensions.width / renderedContentDimensions.width;
  } else {
    // Rendered content is more portrait than the viewport, so fit by height
    scale = canvasDimensions.height / renderedContentDimensions.height;
  }

  return scale;
}

export const resizeIsNeeded = (
  oldProps: ViewportProps,
  newProps: ViewportProps,
  initialPos?: ICoordinate
) => (newProps.width !== oldProps.width || newProps.height !== oldProps.height) && !initialPos;

export function adjustClampRatio(floorPlanViewport: IViewport | undefined, viewport: RawViewport) {
  const vwHeight = floorPlanViewport?.height || 1;
  const ratiHei = vwHeight / viewport.screenHeight;

  return ratiHei > 3 ? ratiHei : 3;
}
