import React, { useEffect, useState } from "react";
import { Paper, styled } from "@mui/material";
import { ColleagueCardFooter } from "../components/ColleagueCardFooter/colleague-card-footer.component";
import { ColleagueCardHeader } from "../components/ColleagueCardHeader/colleague-card-header.component";
import { ColleagueCardPermissions } from "../components/ColleagueCardPermissions/colleague-card-permissions.component";
import { Colleague } from "../types/Colleague.type";

type P = {
  member: Colleague;
  onRefetch: () => void;
};

const ColleagueCard: React.FC<P> = ({ member, onRefetch }) => {
  const [colleague, setColleague] = useState(member);

  useEffect(() => {
    setColleague(member);
  }, [member]);

  return (
    <>
      <StyledColleagueCardPaper data-testid="colleague-card">
        <ColleagueCardHeader colleague={colleague} />
        <ColleagueCardPermissions
          colleague={colleague}
          onColleagueChange={setColleague}
          onRefetch={onRefetch}
        />
        <ColleagueCardFooter
          colleague={colleague}
          onColleagueChange={setColleague}
          onRefetch={onRefetch}
        />
      </StyledColleagueCardPaper>
    </>
  );
};

export default ColleagueCard;

export const StyledColleagueCardPaper = styled(Paper, {
  name: "StyledAdminSelectionPaper"
})(() => ({
  borderRadius: 9,
  position: "relative",
  width: 300,
  padding: 10,
  margin: "10px 10px 10px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap"
}));
