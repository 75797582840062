import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs
} from "@mui/material";
import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import i18n from "i18next";
import { FileObject } from "react-mui-dropzone";
import { handleArray } from "./dialog.functions";

export type MS<A> = {
  isOpen: boolean;
  wasOpened?: boolean;
  data?: A | null;
  /** Some modals have different modes. Modals that use mode default to "add". */
  floorSelectionOption?: [];
  mode?: "add" | "edit";
  /** Some modals require a timezone to be provided. */
  timezone?: string;
  /** Some modals require validation. */
  error?: (string | number)[];
  /** Some modals allow a file upload. */
  files?: FileObject[];
};

type FormikProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  initialValues?: T;
  /** Form submit handler. */
  handleOk: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;

  validate?: (
    values: Record<string, unknown>
  ) => void | object | Promise<FormikErrors<Record<string, unknown>>>;
  validationSchema?: any;
};

type P = FormikProps & {
  /** Determines whether Dialog is open. */
  isOpen: boolean;
  /** @deprecated Class name for dialog wrapper. */
  className?: string;

  /** Title of Dialog */
  title?: string;
  /** Optional description, shown below title. */
  description?: string;

  /** Children of Form Dialog should be form inputs. If there are multiple react children, they will be shown in tabs. */
  children?: React.ReactElement<any> | (React.ReactElement<any> | null)[];
  /**
   * Labels for tabs. Should be in the same order as children.
   * If there are more tabs than children, tabs which don't have a child will not be shown.
   * If there are more children than tabs, labels will default to "more"
   */
  tabLabels?: string[];

  /** @deprecated Form submit handler. Should close the modal */
  // handleOk: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

  /**
   * Disables ok button.
   * @deprecated Don't use this, use form validation.
   */
  disableOK?: boolean;
  /** Label of primary button, defaults to "Confirm". */
  primaryButtonLabel?: string;

  /** Form cancel handler. */
  handleCancel?(): void;
  /** Label of secondary button, defaults to "Cancel". */
  secondaryButtonLabel?: string;
  /** Hide secondary */
  hideSecondaryButton?: boolean;

  /** Modal will be shown in fullscreen if true. */
  fullScreen?: boolean;

  /** Disables tabs (tabLabels will be ignored) */
  disableTabs?: boolean;

  /** @deprecated Don't pass styles */
  style?: React.CSSProperties;
};

/**
 * Wrapper for a Popup/Modal/Dialog Box that opens/closes depending on isOpen.
 */
export const FormDialog: React.FC<P> = ({
  title = i18n.t("Dialog"),
  description,
  secondaryButtonLabel = i18n.t("Cancel"),
  primaryButtonLabel = i18n.t("Confirm"),
  isOpen = false,
  initialValues = { fritz: "kopf" },
  validate,
  validationSchema,
  handleCancel = undefined,
  handleOk,
  children,
  tabLabels,
  hideSecondaryButton = false,
  fullScreen,
  style,
  disableOK,
  disableTabs
}) => {
  handleArray(children, tabLabels, disableTabs);

  const [tabIndex, setTabIndex] = useState<number>(0);
  useEffect(() => {
    if (!isOpen) setTabIndex(0);
  }, [isOpen]);

  const handleTabChange = (event: React.SyntheticEvent<{}>, index: number) => {
    setTabIndex(index);
  };

  const getTabs = (tabsContent: (React.ReactElement<any> | null)[], labels: string[]) =>
    tabsContent.map(
      (element, index) => element && <Tab label={labels[index]} key={labels[index]} />
    );

  const getTabContent = (tabsContent: (React.ReactElement<any> | null)[]) =>
    tabsContent.map(
      (element, i) =>
        element && (
          <Box
            key={element.key}
            role="tabpanel"
            hidden={tabIndex !== i}
            id={`wrapped-tabpanel-${i}`}
            aria-labelledby={`wrapped-tab-${i}`}
          >
            {element}
          </Box>
        )
    );

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      sx={{ maxHeight: "80vh" }}
      aria-labelledby="form-dialog-title"
      style={style}
      maxWidth="md"
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOk}
        validate={validate}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {description && <DialogContentText>{description}</DialogContentText>}
            <Grid>
              {!disableTabs && Array.isArray(children) && tabLabels ? (
                <>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    {getTabs(children as React.ReactElement[], tabLabels)}
                  </Tabs>
                  {getTabContent(children as React.ReactElement[])}
                </>
              ) : (
                children
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {!hideSecondaryButton && (
              <Button onClick={handleCancel} variant="contained">
                {secondaryButtonLabel}
              </Button>
            )}
            <Button color="primary" variant="contained" type="submit" disabled={disableOK}>
              {primaryButtonLabel}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default FormDialog;
