import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

interface RequestBody {
  start: string;
  end: string | null;
  floorInventoryId: number;
  zones: any[];
}

export function useRemoteUpdateFloorPlan() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, RequestBody>({
    url: `/plan`,
    key: ["plan"],
    method: "post",
    snackbarTextOnSuccess: t("Successfully updated the floor plan"),
    snackbarTextOnFail: t("Failed to update the floor plan")
  });
}
