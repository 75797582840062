import React, { PropsWithChildren, useRef, useState } from "react";
import { Container, Text } from "@pixi/react";
import {
  Container as RawContainer,
  DisplayObject,
  Text as RawText,
  TextStyle,
  EventMode
} from "pixi.js";
import { useTranslation } from "react-i18next";
import Position from "../../Domain/Types/Position.type";
import BoundingBox from "../../Domain/Types/BoundingBox.type";
import { RENDER_PLACE_ZINDEX } from "../../Domain/Constants/ZIndexConstants.constant";
import PlaceSprite from "./PlaceSprite";
import { Pos } from "./Helpers/Pos";
import { PlaceIcon } from "./PlaceIcon";
import { useFeatures } from "../../Hooks/useFeatures";
import { ToolSelection } from "../Views/CreateFloorPlanView/CreateFloorPlanView";
import { PlaceVariant } from "../../Domain/Types/FloorPlan/PlaceVariant.type";

interface Props {
  id: number;
  inventoryId?: number;
  position: Position;
  rotation: number;
  boundingBox: BoundingBox;
  colorOverlay?: number;
  isHighlighted?: boolean;
  onClick?: (instance: number, inventoryId?: number) => void;
  icon?: string;
  showId?: boolean;
  variant: PlaceVariant;
  isSelectable: boolean;
  isSelected?: boolean;
  eventMode?: EventMode;
  placeTypeId: number | undefined;
  placeScale: number;
  isMultiTouches?: number;
  tool?: ToolSelection;
}

export function PlaceContainer({
  position,
  rotation,
  isSelectable,
  onClick,
  boundingBox,
  id,
  inventoryId,
  colorOverlay,
  isHighlighted,
  icon,
  variant,
  showId = false,
  isSelected,
  eventMode = "static",
  placeTypeId = 1,
  placeScale,
  isMultiTouches,
  tool
}: PropsWithChildren<Props>) {
  const alpha = 1.0;

  const { t } = useTranslation();
  const { useHelperPoint } = useFeatures();

  const textRef = useRef<RawText>(null);
  const rootRef = useRef<RawContainer<DisplayObject> | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isPointerDown, setIsPointerDown] = useState<boolean>(false);

  const checkDrawInventoryId = (inventoryId: number | undefined, showId: boolean) => {
    return !Number.isNaN(inventoryId) && inventoryId !== undefined && showId;
  };
  const indicateNoInventory = (inventoryId: number | undefined) => {
    return Number.isNaN(inventoryId);
  };

  const handlePointerUp = () => {
    if (!isSelectable) return;
    // prohibit selection when multi touches
    if (isMultiTouches && isMultiTouches > 1) return;
    // check tool selection and if dragging is not necessary then quickly handle pointer up
    if (isDragging && tool !== undefined) {
      setIsDragging(false);
      setIsPointerDown(false);
      return;
    }
    setIsPointerDown(false);
    setIsDragging(false);
    onClick && onClick(id, inventoryId);
  };

  const handlePointerDown = () => {
    setIsPointerDown(true);
  };

  const handlePointerMove = () => {
    if (isPointerDown) setIsDragging(true);
  };

  return (
    <Container
      name="place-box"
      data-testid={"place-container"}
      ref={rootRef}
      alpha={alpha}
      eventMode={eventMode}
      position={position}
      angle={rotation}
      zIndex={RENDER_PLACE_ZINDEX}
      onpointerup={handlePointerUp}
      onpointerdown={handlePointerDown}
      onpointermove={handlePointerMove}
    >
      {/** draw the texture */}
      <PlaceSprite
        variant={variant}
        boundingBox={boundingBox}
        colorOverlay={colorOverlay}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        placeTypeId={placeTypeId}
        placeScale={placeScale}
        icon={icon}
        isSelectable={isSelectable}
        tool={tool}
      />

      {useHelperPoint && <Pos />}
      <PlaceIcon
        icon={icon}
        placeScale={placeScale}
        placeTypeId={placeTypeId}
        isSelectable={isSelectable}
        boundingBox={boundingBox}
      />

      {/** draw the inventory ID */}
      {checkDrawInventoryId(inventoryId, showId) && (
        <Text
          data-testid="place-container-invenID"
          ref={textRef}
          text={inventoryId!.toString()}
          style={
            new TextStyle({
              fontSize: 15 * placeScale,
              letterSpacing: 0.3 * placeScale,
              fill: 0x000000
            })
          }
          resolution={20}
          x={posForPlaceType(placeTypeId, boundingBox, placeScale).x}
          y={posForPlaceType(placeTypeId, boundingBox, placeScale).y}
        />
      )}
      {indicateNoInventory(inventoryId) && (
        <Text
          data-testid="place-container-noInvenID"
          ref={textRef}
          text={t("No Place Inventory")}
          style={
            new TextStyle({
              fontSize: 15 * placeScale,
              letterSpacing: 0.5 * placeScale,
              fill: 0xff0000
            })
          }
          resolution={20}
          x={posForPlaceType(placeTypeId, boundingBox, placeScale).x}
          y={posForPlaceType(placeTypeId, boundingBox, placeScale).y}
        />
      )}
    </Container>
  );
}

export function posForPlaceType(
  placeTypeId: number,
  boundingBox: BoundingBox,
  placeScale: number
): { x: number; y: number } {
  const normalWorkplacePos = {
    x: ((10 - boundingBox.width) * placeScale) / 2,
    y: (boundingBox.height - 40) * placeScale
  };
  const parkingSpacePos = {
    x: ((10 - boundingBox.width) * placeScale) / 2,
    y: (boundingBox.height / 2 - 25) * placeScale
  };

  switch (placeTypeId) {
    case 1: // normal workplace type
      return normalWorkplacePos;
    case 2: // car parking place
      return parkingSpacePos;
    case 4: // electric charging station
      return parkingSpacePos;
    default:
      return normalWorkplacePos;
  }
}
