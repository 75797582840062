import { Box, IconButton } from "@mui/material";
import { EditCalendar, Lock, LockOutlined } from "@mui/icons-material";
import { PermissionUnitEntries } from "../PermissionSelect";

type PermissionSelectSingleChipProps = {
  option: PermissionUnitEntries;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handlePopoverClose: () => void;
  handleStricPermChange: (e: any, value: any) => void;
  setSelectOpt: (o: PermissionUnitEntries | null) => void;
};

export function PermissionSelectSingleChip(props: PermissionSelectSingleChipProps) {
  const { option, handlePopoverOpen, handlePopoverClose, handleStricPermChange, setSelectOpt } =
    props;
  return (
    <Box data-testid="perm-type-btn-group" sx={{ pl: 0.7 }}>
      <IconButton
        data-testid="stric-perm-btn"
        sx={{ padding: 0.1 }}
        onMouseOver={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={e => handleStricPermChange(e, option)}
      >
        {option.enforceUsage ? <Lock color={"info"} /> : <LockOutlined />}
      </IconButton>

      {/* a button to open the weekday permission modal open */}
      <IconButton
        sx={{ padding: 0.1, marginRight: "-5px" }}
        data-testid="weekday-perm-modal-open-btn"
        onClick={() => setSelectOpt(option)}
      >
        <EditCalendar />
      </IconButton>
    </Box>
  );
}
