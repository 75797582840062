import { PropsWithChildren, useEffect, useState } from "react";
import { Sprite } from "@pixi/react";
import { Point } from "pixi.js";
import { Viewport } from "pixi-viewport";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { GhostZoneContainer } from "../../GhostZone/GhostZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { DrawZone } from "./Tools/DrawZone/DrawZone";
import { DrawPlaces } from "./Tools/DrawPlaces/DrawPlaces";
import {
  FloorPlanUpdateStatus,
  generateNegativeIds,
  useFloorplan
} from "./Tools/useFloorplan/useFloorplan";
import { useBackgroundImage } from "../../../Hooks/useBackgroundImage";
import { TopToolbar } from "../../Toolbars/TopToolbar/TopToolbar";
import { QrCodeComponent } from "../../Modals/QrCodeModal/QrCodeComponent";
import { SideToolbar } from "../../Toolbars/SideToolbar/SideToolbar";
import { useSelection } from "../../../Hooks/useSelection/useSelection";
import { ZoneSelectionBorder } from "./Tools/ZoneSelectionBorder/ZoneSelectionBorder";
import { ITimeframe } from "../../../Domain/Types/FloorPlan/Timeframe.type";
import { useAssets } from "../../../Hooks/useAssets";
import { useGeometry } from "../../../Hooks/useGeometry";
import { PlaceSideToolbar } from "../../Toolbars/PlaceSideToolbar/PlaceSideToolbar";
import { MultiPlaceInfoSideBar } from "../../Toolbars/InfoSidebar/PlaceInfoSidebar/MultiPlaceInfoSidebar";
import { useCanvasPrint } from "../../../Hooks/useCanvasPrint";
import { PrintFloorModal } from "../../Modals/PrintFloorModal/PrintFloorModalComponent";
import {
  boundingBoxPerPlaceType,
  checkDeskPos,
  checkIfIsEditable,
  checkIfPublishIsAvailable,
  checkPlaceDisabledStatus,
  checkToolAndMouseOverZone,
  checkToolSelection,
  handleClickedZone,
  onClickPlace
} from "./Functions/CreateFloorPlanView.functions";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { ZoneSideToolbar } from "../../Toolbars/ZoneSideToolbar/ZoneSideToolbar";
import { ZoneInfoSidebarSwitch } from "../../Toolbars/InfoSidebar/ZoneInfoSidebar/ZoneInfoSidebar.switch";

export type MovementPos = {
  moveX: number;
  moveY: number;
};

interface Props {
  width: number;
  height: number;
  name: string;
  floorPlan: IFloorPayload;
  selectedTimeframe: ITimeframe;
  selectedFloorId: number;
  locationInventoryId: number;
  backgroundImageUrl: string;
  floorPlanUpdateStatus: FloorPlanUpdateStatus;
  onPublish: (newFloorPlan: IFloorPayload) => void;
  onAbort: () => void;
  onChangeBackgroundImage?: (newImageUrl: string) => void;
}

export type ToolSelection = "ZONE.NEW" | "DESK.NEW" | "SELECT.BOOK" | undefined;

export function CreateFloorPlanView({
  height,
  children,
  name,
  backgroundImageUrl,
  floorPlan,
  selectedTimeframe,
  locationInventoryId,
  onPublish,
  onAbort,
  selectedFloorId,
  floorPlanUpdateStatus
}: PropsWithChildren<Props>) {
  const { enqueueSnackbar } = useSnackbar();

  const { location: selectedLoc } = useSelector((state: RootState) => state.floorManager.selected);
  const { inventory: locInventory } = useSelector(
    (state: RootState) => state.floorManager.locations
  );

  // check wheter location inventory has site id
  const hasSiteId = !!locInventory.find(loc => loc.id === selectedLoc)?.exivoSiteId;

  const isFloorPlanForService =
    !!floorPlan.tableArrangementAssignments && !!floorPlan.conferenceZoneLinks;

  const isEditable: boolean = checkIfIsEditable(selectedTimeframe, isFloorPlanForService);

  const [tool, setTool] = useState<ToolSelection>(undefined);
  const [viewport, setViewPort] = useState<undefined | Viewport>(undefined);
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [showId, setShowId] = useState(true);
  const [blockViewportMovement, setBlockViewportMovement] = useState(false);
  const [extractCanvas, setExtractCanvas] = useState<null | HTMLImageElement>(null);
  const [isMouseOverZone, setIsMouseOverZone] = useState(false);
  const [showPlaceAdd, setShowPlaceAdd] = useState(false);
  const [showZoneAdd, setShowZoneAdd] = useState(false);
  const [isMultiPlaces, setIsMultiPlaces] = useState(false);
  const [numberOfPlacesAdd, setNumberOfPlacesAdd] = useState(1);
  const [typeOfPlaces, setTypeOfPlaces] = useState(1);
  const [typeOfZone, setTypeOfZone] = useState(1);
  const [enablePublish, setEnablePublish] = useState(false);
  const [isZnInvModalOpen, setIsZnInvModalOpen] = useState<boolean>(false);
  const [isExtractingCanvas, setIsExtractingCanvas] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);

  // count number of touches on the pixi stage
  const [isMultiTouches, setIsMultiTouches] = useState(0);

  // download and set the background image here
  const { background } = useBackgroundImage({ backgroundImageUrl, viewport: floorPlan.viewport });

  // load knob icons assets
  const { knobIcons } = useAssets();

  const { convertPixiPoints } = useGeometry();

  const { printCanvas } = useCanvasPrint(name, extractCanvas);
  const handlePrintFloor = () => {
    // update the canvas capture scene
    wipeSelection();
    setIsPrintModalOpen(true);
  };

  // handle automatically unselecting and pressing shift
  const {
    selectDesk,
    selectedDesks,
    wipeSelection,
    removeDeskFromSelection,
    removeZoneFromSelection,
    selectZone,
    selectedZones
  } = useSelection(tool);

  const {
    floorPlan: currentFloorPlan,
    addZone,
    transFormDesks,
    stretchDesks,
    deleteDesk,
    addDesks,
    deleteZone,
    transformZone,
    changeZone,
    changeDesk,
    editConferenceService,
    updateZoneInventory,
    updatePlaceInventory,
    inventoryManagement,
    categoryManagement,
    dispatchFloorPlan,
    canUndo,
    canRedo,
    equipmentManagement
  } = useFloorplan(
    floorPlan || {
      floorInventoryId: selectedFloorId,
      freeInventories: {
        places: [],
        zones: []
      },
      zones: [],
      places: []
    },
    selectedTimeframe,
    floorPlanUpdateStatus
  );

  const highlightedPlace = currentFloorPlan.places.filter(workplace =>
    selectedZones.includes(workplace.zoneScheduleId)
  );

  function onClickZone(selectedZoneId: number, mousePos: Point) {
    const deskPos = viewport?.toWorld(mousePos.x, mousePos.y);

    checkDeskPos(deskPos);

    handleClickedZone(
      tool,
      selectedZoneId,
      selectedTimeframe,
      { deskPos, numberOfPlacesAdd, placeScale, typeOfPlaces },
      addDesks,
      selectZone
    );
  }

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  useEffect(() => {
    // unblock the viewport in case it is still blocked for some reason
    checkToolSelection(tool, setBlockViewportMovement);
    // wipe place selection to avoid confusion
    wipeSelection();
  }, [tool]);

  useEffect(() => {
    // check every places and zones have both inventory & category. and if not, cannot publish the floor plan
    checkIfPublishIsAvailable(currentFloorPlan, setEnablePublish);
  }, [currentFloorPlan]);

  // update the canvas capture scene after selection & changes are occured
  useEffect(() => {
    if (selectedDesks.length >= 1 || selectedZones.length >= 1) setIsExtractingCanvas(false);
    else setIsExtractingCanvas(true);
  }, [selectedDesks, selectedZones, isExtractingCanvas]);

  return (
    <>
      {!background && (
        <CircularProgress size={50} sx={{ position: "absolute", top: "48%", left: "48%" }} />
      )}
      {background && !!placeScale && (
        <DisplayViewport
          onInit={v => setViewPort(v)}
          displayTopToolbar
          floorPlanName={name}
          initialZoomEnd={new Point(background.width, background.height)}
          blockViewportMovement={blockViewportMovement}
          onExtract={instance => setExtractCanvas(instance)}
          isExtractingCanvas={isExtractingCanvas}
          currentFloorPlan={currentFloorPlan}
          viewportDimensions={viewportDimensions}
          setViewportDimensions={setViewportDimensions}
          setIsMultiTouches={setIsMultiTouches}
          toolbars={
            <>
              {/* top bar to select other functions */}
              <TopToolbar
                floorPlanName={name}
                floorPlan={currentFloorPlan}
                qrCodeOpen={qrCodeOpen}
                setQrCodeOpen={o => setQrCodeOpen(o)}
                showId={showId}
                setShowId={s => setShowId(s)}
                onClickPublish={() => {
                  onPublish(currentFloorPlan);
                  wipeSelection();
                }}
                dispatchFloorPlan={dispatchFloorPlan}
                canUndo={canUndo}
                canRedo={canRedo}
                onClickAbort={onAbort}
                printFloorPlan={handlePrintFloor}
                isEditable={isEditable}
                enablePublish={enablePublish}
                isFloorPlanForService={isFloorPlanForService}
              />
              {isEditable ? (
                <>
                  {/* side bar to select tools */}
                  <SideToolbar
                    selectedTool={tool}
                    onSelectTool={t => setTool(t)}
                    setShowPlaceAdd={p => setShowPlaceAdd(p)}
                    setIsMultiPlaces={m => setIsMultiPlaces(m)}
                    setTypeOfPlaces={t => setTypeOfPlaces(t)}
                    setNumberOfPlacesAdd={a => setNumberOfPlacesAdd(a)}
                    setShowZoneAdd={z => setShowZoneAdd(z)}
                    setTypeOfZone={t => setTypeOfZone(t)}
                    isFloorPlanForService={isFloorPlanForService}
                  />
                  {/* create new places single or multi */}
                  {showPlaceAdd && (
                    <PlaceSideToolbar
                      isMultiPlaces={isMultiPlaces}
                      setIsMultiPlaces={m => setIsMultiPlaces(m)}
                      setNumberOfPlacesAdd={n => setNumberOfPlacesAdd(n)}
                      typeOfPlaces={typeOfPlaces}
                      setTypeOfPlaces={t => setTypeOfPlaces(t)}
                      availableTypes={currentFloorPlan.placeTypes}
                    />
                  )}
                  {/* create new zone and select type */}
                  {showZoneAdd && (
                    <ZoneSideToolbar
                      typeOfZone={typeOfZone}
                      setTypeOfZone={t => setTypeOfZone(t)}
                      availableTypes={currentFloorPlan.zoneTypes}
                    />
                  )}
                  {selectedDesks.length > 0 && (
                    <MultiPlaceInfoSideBar
                      height={height}
                      placeSchedules={currentFloorPlan.places.filter(workplace =>
                        selectedDesks.includes(workplace.id)
                      )}
                      floorPlan={currentFloorPlan}
                      locationInventoryId={locationInventoryId}
                      onChangeTransform={(deskIds, t) => /* istanbul ignore next */ {
                        transFormDesks({ ids: deskIds, transform: t });
                      }}
                      onChangeStretch={(deskIds, t) => stretchDesks({ ids: deskIds, stretch: t })}
                      onClickDelete={deskIds => {
                        for (const id of deskIds) removeDeskFromSelection(id);
                        deleteDesk(deskIds);
                      }}
                      onCloseDesk={() => {
                        wipeSelection();
                      }}
                      onChangeName={(ids, newDeskName) => changeDesk(ids, { name: newDeskName })}
                      onChangeDescription={(ids, description) =>
                        changeDesk(ids, { description: description })
                      }
                      onChangeEnabledStatus={(ids, status) => changeDesk(ids, { enabled: status })}
                      onChangePerm={(ids, newPerms) => changeDesk(ids, { perms: newPerms })}
                      inventoryManagement={inventoryManagement}
                      categoryManagement={categoryManagement}
                      equipmentManagement={equipmentManagement}
                      updatePlaceInventory={updatePlaceInventory}
                      floorPlanName={name}
                    />
                  )}
                  {selectedZones.length > 0 && (
                    <ZoneInfoSidebarSwitch
                      updateZoneInventory={updateZoneInventory}
                      height={height}
                      zoneSchedules={currentFloorPlan.zones.filter(zone =>
                        selectedZones.includes(zone.id)
                      )}
                      floorPlan={currentFloorPlan}
                      inventoryManagement={inventoryManagement}
                      categoryManagement={categoryManagement}
                      equipmentManagement={equipmentManagement}
                      isZnInvModalOpen={isZnInvModalOpen}
                      hasSiteId={hasSiteId}
                      floorPlanName={name}
                      setIsZnInvModalOpen={(o: boolean) => setIsZnInvModalOpen(o)}
                      onClose={wipeSelection}
                      onClickDelete={ids => {
                        for (const id of ids) removeZoneFromSelection(id);
                        wipeSelection();
                        deleteZone(ids);
                      }}
                      onChangeZoneDescription={description =>
                        changeZone(selectedZones, { description })
                      }
                      onChangeName={(ids, newZoneName) => changeZone(ids, { name: newZoneName })}
                      onChangeEnabledStatus={(ids, status) => changeZone(ids, { enabled: status })}
                      onChangePerm={(ids, newPerms) => changeZone(ids, { perms: newPerms })}
                      onChangeUserNumber={(ids, newUserNumber) =>
                        changeZone(ids, { numberOfUser: newUserNumber })
                      }
                      onChangeConfService={(id, newServ) =>
                        editConferenceService(id, { tableAssignment: newServ })
                      }
                    />
                  )}
                </>
              ) : null}

              {/** print QR Code for workplaces */}
              <QrCodeComponent
                open={qrCodeOpen}
                floorPlanName={name}
                entries={currentFloorPlan.places}
                onClose={() => setQrCodeOpen(false)}
              />
              {/** print the floor plan */}
              <PrintFloorModal
                isPrintModalOpen={isPrintModalOpen}
                setIsPrintModalOpen={setIsPrintModalOpen}
                printFloor={() => {
                  printCanvas();
                  setIsPrintModalOpen(false);
                }}
              />
            </>
          }
        >
          {/** Background image, usually a floor plan */}
          <Sprite texture={background} name={"background-spr"} data-testid="background-spr" />

          {/** draw zones container minus the selected one */}
          {currentFloorPlan.zones
            .filter(z => !selectedZones.includes(z.id))
            .map(zone => (
              <ZoneContainer
                key={uuidv4()}
                viewport={viewport}
                id={zone.id}
                inventoryId={zone.inventory?.id || zone.inventoryId}
                walls={convertPixiPoints(zone.coordinates)}
                onClick={onClickZone}
                onPointerEnter={() => checkToolAndMouseOverZone(tool, "Enter", setIsMouseOverZone)}
                onPointerLeave={() => checkToolAndMouseOverZone(tool, "Leave", setIsMouseOverZone)}
                showId={showId}
                isClickable={isEditable}
                disabled={zone.disabled}
                zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
                isMultiTouches={isMultiTouches}
                tool={tool}
              />
            ))}

          {/** ghost zone when a zone is selected, to move zones */}
          {selectedZones.length > 0 && (
            <GhostZoneContainer
              initialZones={currentFloorPlan.zones.filter(z => selectedZones.includes(z.id))}
              isEditable={isEditable}
              knobIcon={knobIcons?.MoveKnobIcon}
              viewport={viewport}
              showId={showId}
              isFloorPlanForService={isFloorPlanForService}
              onZoneWallsChange={zoneTransforms => transformZone(...zoneTransforms)}
              onClick={onClickZone}
            />
          )}

          {/** draw places minus the selected ones */}
          {currentFloorPlan.places
            .filter(r => !selectedDesks.includes(r.id))
            .map(workplace => (
              <PlaceContainer
                key={uuidv4()}
                boundingBox={{
                  width: boundingBoxPerPlaceType(workplace).width,
                  height: boundingBoxPerPlaceType(workplace).height
                }}
                id={workplace.id}
                inventoryId={workplace.inventoryId}
                rotation={workplace.rotate}
                position={workplace.position}
                variant={checkPlaceDisabledStatus(workplace.disabled)}
                isSelectable={tool === undefined && !isFloorPlanForService}
                isSelected={selectedDesks.includes(workplace.id)}
                onClick={() => onClickPlace(workplace.id, tool, selectDesk)}
                showId={showId}
                isHighlighted={highlightedPlace.map(h => h.id).includes(workplace.id)}
                placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
                placeScale={placeScale}
                isMultiTouches={isMultiTouches}
                tool={tool}
              />
            ))}

          {/** creates a box containing a copy of selected places so a user can transform them quickly */}
          {viewport && tool === undefined && selectedDesks.length > 0 && (
            <ZoneSelectionBorder
              initialDesks={currentFloorPlan.places.filter(wp => selectedDesks.includes(wp.id))}
              viewport={viewport}
              knobIcons={knobIcons}
              onTransformPlace={objectTransforms => transFormDesks(...objectTransforms)}
              onStretch={objectStretch => stretchDesks(...objectStretch)}
              onClickDesk={deskId => onClickPlace(deskId, tool, selectDesk)}
              isEditable={isEditable}
              showId={showId}
              placeScale={placeScale}
            />
          )}

          {/** create new zone */}
          {tool === "ZONE.NEW" && (
            <DrawZone
              tool={tool}
              onCreateZone={zone => {
                addZone(zone, selectedTimeframe, typeOfZone);
                selectZone(generateNegativeIds(currentFloorPlan.zones));
                setIsZnInvModalOpen(true);
              }}
              viewport={viewport}
              enqueueSnackbar={enqueueSnackbar}
            />
          )}

          {/** create new places */}
          {tool === "DESK.NEW" && isMouseOverZone && numberOfPlacesAdd > 0 && (
            <DrawPlaces
              viewport={viewport}
              numberOfPlacesAdd={numberOfPlacesAdd}
              placeTypeId={typeOfPlaces}
              placeScale={placeScale}
            />
          )}

          {/** additional containers for extra features if needed */}
          {children}
        </DisplayViewport>
      )}
    </>
  );
}
