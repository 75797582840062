import { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import { t } from "i18next";
import { useSelector } from "../../../../../../../app/helpers";
import { RootState } from "../../../../../../../app/rootReducer";
import { WeekdayNames } from "../../../../../Domain/Types/FloorPlan/WeekdayNames.type";
import { PermissionUnitEntries } from "../PermissionSelect";

type WeekdayPermissionDialogProps = {
  option: PermissionUnitEntries;
  initialPermWeekdays: WeekdayNames[];
  handleActiveWkdayChange: (option: any, value: WeekdayNames[]) => void;
  setSelectOpt: (o: PermissionUnitEntries | null) => void;
};
export const WeekdayPermissionDialog: React.FC<WeekdayPermissionDialogProps> = forwardRef<
  HTMLDivElement,
  WeekdayPermissionDialogProps
>((props, ref) => {
  const { option, initialPermWeekdays, handleActiveWkdayChange, setSelectOpt } = props;
  const { bookingsOnSaturdaysAllowed, bookingsOnSundaysAllowed } = useSelector(
    (state: RootState) => state.login.userInformation.company.meta
  );

  const [newVal, setNewVal] = useState<WeekdayNames[]>(initialPermWeekdays);

  const handleWeekdayToggle = (
    event: React.MouseEvent<HTMLElement>,
    newToggles: WeekdayNames[]
  ) => {
    setNewVal(newToggles);
  };

  const isOpen = !!option;
  const handleClose = () => setSelectOpt(null);

  const handleSave = () => {
    handleActiveWkdayChange(option, newVal);
    handleClose();
  };

  return (
    <Dialog open={isOpen} ref={ref}>
      <DialogTitle>
        <Grid container data-testid="restrict-perm-per-weekday">
          <Grid item xs={1} />
          <Grid sx={{ textAlign: "center", alignSelf: "center" }} item xs={10}>
            <Typography variant="h6">{t("Restrict Permissions Per Weekday")}</Typography>
          </Grid>

          <Grid sx={{ textAlign: "right" }} item xs={1}>
            <IconButton data-testid="btn-close" onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <ToggleButtonGroup
          value={newVal}
          onChange={handleWeekdayToggle}
          data-testid="weekday-perm-toggle-btn-group"
          sx={{ display: "flex", flexWrap: "wrap" }}
        >
          <WeekdayPermToggle
            weekday={WeekdayNames.MONDAY}
            initialValue={newVal.includes(WeekdayNames.MONDAY)}
          />
          <WeekdayPermToggle
            weekday={WeekdayNames.TUESDAY}
            initialValue={newVal.includes(WeekdayNames.TUESDAY)}
          />
          <WeekdayPermToggle
            weekday={WeekdayNames.WEDNESDAY}
            initialValue={newVal.includes(WeekdayNames.WEDNESDAY)}
          />
          <WeekdayPermToggle
            weekday={WeekdayNames.THURSDAY}
            initialValue={newVal.includes(WeekdayNames.THURSDAY)}
          />
          <WeekdayPermToggle
            weekday={WeekdayNames.FRIDAY}
            initialValue={newVal.includes(WeekdayNames.FRIDAY)}
          />
          {bookingsOnSaturdaysAllowed && (
            <WeekdayPermToggle
              weekday={WeekdayNames.SATURDAY}
              initialValue={newVal.includes(WeekdayNames.SATURDAY)}
            />
          )}
          {bookingsOnSundaysAllowed && (
            <WeekdayPermToggle
              weekday={WeekdayNames.SUNDAY}
              initialValue={newVal.includes(WeekdayNames.SUNDAY)}
            />
          )}
        </ToggleButtonGroup>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", padding: "16px 24px" }}>
        <Button data-testid={"weekday-perm-save-btn"} onClick={handleSave} sx={{ width: "100%" }}>
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

type WeekdayPermToggleProps = {
  weekday: WeekdayNames;
  initialValue: boolean;
};

export const WeekdayPermToggle: React.FC<WeekdayPermToggleProps> = ({ weekday, initialValue }) => {
  return (
    <ToggleButton value={weekday} data-testid={`${weekday}-toggle-btn`}>
      {t(weekday)}
      {initialValue ? (
        <CheckBox sx={{ ml: 1 }} color="primary" />
      ) : (
        <CheckBoxOutlineBlank sx={{ ml: 1 }} color="primary" />
      )}
    </ToggleButton>
  );
};
